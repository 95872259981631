import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const PeriorbitalDarkCircleCrowsFeet: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Periorbital Dark Circle / Crows Feet</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
            
              <h5>THE COMMON COMPLAINTS ARE</h5> 
<p>Dark Circles
Deep Set Eyes / Hollow Eyes
Eye Bags
Fine Lines and Wrinkles (Crow’s feet)
Thinning of Eye Lashes
Thinning of Eyebrows
Tired Eyes</p>
<h5> COMMON CAUSES</h5>
<p>Frequent cold/ Allergies/ Atopic Dermatitis (eczema)
Frequently Rubbing the Eyes
Using Harsh Cleansers
Nutritional Deficiency
Deep Set Eyes
Thin skin Vasculature
Hereditary
Lifestyle factors like Smoking, Chronic Alcoholism, Emotional Stress</p>
<h5>PREVENTION</h5> 
<p>Include a high dose of vitamin C to the diet to prevent recurrent colds
Avoid rubbing your eyes often
Use bland, water-based and a soap-free gentle cleanser for the eyes
Correct any existing iron or zinc deficiency
Stop smoking as it causes free radical damage enhancing dark circles.</p>
<h5> NON-SURGICAL EYE HOLLOWS/DEEP SET EYES</h5>
<h5>CAUSE</h5> 
<p>Eye hollows or tear troughs are the grooves that sits between your lower eyelids and cheeks. They often make your eyes look tired. With age, the fat under the eyes begin to protrude, and the grooves seem worse</p>

<h5>TREATMENT</h5> 
<p>Surgery is normally the only way to remove the protrusion of fat from under the eye. However, by filling in the tear trough underneath it, the eye bags can appear less prominent, thus making the transition from the eyes to the cheeks smoother. This procedure is done by depositing hyaluronic-based dermal fillers in the hollow which lift the groove and fill the tear trough. Because the movement in this area is not that much, dermal fillers last much longer here, generally between 1-1.5 years. Usually only one syringe is required for this area. Other treatments that can be used for this are</p>
<p>Botox Injections…(Read More)
Dermal Fillers…(Read More)
Gel peels…(Read More)
Caci Treatment…(Read More)
Accent Ultra Skin Tightening RF…(Read More)
Vampire Facelift…(Read More)</p>

<h5>WHY SKIN & YOU CLINIC FOR PERIORBITAL DARK CIRCLE/CROW’S FEET?</h5> 
<p>Skin & You Clinic, We will be able to discuss your concerns with you and make an appropriate assessment as to how adding facial volume may help you achieve a younger and natural appearance.</p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PeriorbitalDarkCircleCrowsFeet;
