import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const AccentUltraBodyShaping: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Accent Ultra Body Shaping</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
         

          
              <h5>RESHAPE YOUR BODY SAFELY AND EFFECTIVELY</h5> 
<p>BODY360 is a body contouring solution that can help you look and feel younger. Through this innovative treatment, you can slim and tone your body without crash diets or marathon exercise programs.

Since its not surgery- there are no incisions, stitiches or even anesthesia required. In fact the BODY360 treatment is clinically proven to be successful at:

Inch Loss
Improvement of cellulite
Skin tightening
Contouring your body
Even more importantly, it is safe, effective and noninvasive. The treatment delivers consistent results without surgery or unwanted side effects. Many patients consider it after surgery or having a baby.
</p>
<h5>SEE A SLIMMER YOU- WITHOUT SURGERY</h5> 
<p>BODY360 with Accent ultra is suitable for patients of all ages, skin types and ethnicities. In addition, you can plan an Accent Ultra treatment at any time of year even with already tanned skin. Typical treatment possibilities include:
</p>
<p>Flatten Stomach
Slim Hips, Thighs and Buttocks
Improve cellulite in targeted areas.
Tighten and tone upper Arms
Reduce Bra Bulge</p>
<h5>VIRTUALLY PAIN FREE</h5> 
<p>Most people find accent ultra very comfortable. The treatment feels like a ‘warm massage’.
</p>
<h5>VISIBLE RESULT IN TWO WEEKS</h5> 
<p>You’ll see visible results as soon as two weeks after starting treatment. BODY360 combined with diet and exercise can reveal even more dramatic results for a slimmer new you. Depending on the size and number of targeted areas, a typical treatment schedule includes:

4-6 SESSIONS
60-90 MINUTES PER SESSION</p>
<h5>HOW DOES IT WORK?</h5> 
<p>BODY360 uses a combination of time-tested, proven technologies including ultrasound and radio-frequency. The innovation is how these technologies are combined into a virtually pain-free, therapeutic treatment option. It is as easy as 1-2-3:

The Accent Ultra module sweeps across your target area(s), gradually heating the skin and tissues underneath as it moves.
Fat and cellulite within the cells break down. Your body treats them as waste products.
You emerge refreshed and relaxed. Many patients report it feels like a warm massage.</p>
<h5>WOULDN’T YOU LOVE TO FEEL BETTER IN A BATHING SUIT OR DROP A SIZE WITH A PAIR OF JEANS, HAVE YOU EVER WISHED THERE WAS A WAY TO FINALLY GET RID OF UNWANTED CELLULITE WITHOUT EXTENSIVE DIETING, EXTREME EXERCISE REGIMENS OR SURGERY?</h5> 
<p>Sometimes diet and exercise can only get you so far. It can be a stubborn metabolism or perhaps hereditary factors have previously prevented you from having the body you wanted. It’s a clinically proven, non-invasive solution to reshape and slim your Body, tighten skin and give you back your body’s natural contours, without expensive surgical procedures.

“I am so pleased with my BODY360 result! I had tried diet and exercise alone for years but no matter how many crunches I did, I couldn’t get rid of my belly fat. Now I have the contoured, flat stomach I’ve been dreaming of without pain, surgery or downtime!”

Katherine H., BODY360 Patient</p>

<h5>WHY SKIN & YOU CLINIC FOR ACCENT ULTRA BODY SHAPING?</h5> 
<p>at Skin & You Clinic, Mumbai, individualised treatment plans are formulated and combinations of treatments are carried out to achieve desired results.</p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccentUltraBodyShaping;
